import axios from 'axios';
import JustValidate from 'just-validate';

/**
 * Contact form validation
 */
document.addEventListener('DOMContentLoaded', function () {
  const form = document.getElementById('contact-form') as HTMLFormElement;
  const contactSuccess = document.getElementById('contact-success') as HTMLElement;
  const contactFail = document.getElementById('contact-fail') as HTMLElement;

  if (form) {
    // Initialize JustValidate with global configuration
    const validation = new JustValidate('#contact-form', {
      validateBeforeSubmitting: true, // Enable validation before form submission
      // Add other global configurations as needed
    });

    // Add validation rules
    validation
      .addField('#firstname', [{
        rule: 'minLength',
        value: 3,
      },
      {
        rule: 'maxLength',
        value: 30,
      },
      ])
      .addField('#email', [{
        rule: 'required',
        errorMessage: 'Email is required',
      },
      {
        rule: 'email',
        errorMessage: 'Email is invalid!',
      },
      ])
      .addField('#msg', [{
        rule: 'required',
        errorMessage: 'Message is required',
      },])
      .onSuccess(() => {
        // Serialize form data
        const formData = new FormData(form);
        const data = Object.fromEntries(formData.entries());

        // Use Axios to send the form data
        axios.post('/wp-json/llp/v1/contact/', data)
          .then(_response => {
            form.classList.add('hidden');
            contactFail.classList.add('hidden');
            contactSuccess.classList.remove('hidden');
          })
          .catch(error => {
            console.error('Error:', error);
            contactFail.classList.remove('hidden');
            contactSuccess.classList.add('hidden');
            // Handle error - e.g., show an error message
          });
      })
      .onFail(() => {
        console.error('Form validation failed');
        // Optionally, you can display an error message to the user here
      });;
  }
});
